import { ADDRESS_STORAGE_NAME, CHANNEL_STORAGE_NAME, URL_PROFILE_CN } from '@/domain/models/address'
import { CountryId } from '@/domain/models/country'
import { MaintenanceAddressRejectedMessages } from '@/domain/models/messages/messages'
import { Page } from '@/domain/models/page/page'
import { StatusCardContainer } from '@/main/components/status-card-container'
import { IdentityInfoContext } from '@/main/contexts'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { removeItemInStorage } from '@/main/hooks/useStorage'
import { createUrlAddressMaintenance } from '@/utils/create-url-address-maintenance'
import { Button, Icon } from '@naturacosmeticos/natds-react'
import React, { useContext } from 'react'
import {
  ActionContainer,
  ContentRejected,
  DescriptionContainer,
  InfoContainer,
  Text,
  Title,
  WarningIcon,
} from './register-maintenance-address-rejected.styles'

export const MaintenanceAddressRejected: React.FC = () => {
  const { personId, consultantId, countryId } = useContext(IdentityInfoContext)

  const messages = usePageMessages(Page.MaintenanceAddressRejected)
    .messages as MaintenanceAddressRejectedMessages

  const redirectToProfile = () => {
    removeItemInStorage(ADDRESS_STORAGE_NAME)
    removeItemInStorage(CHANNEL_STORAGE_NAME)
    if (consultantId === personId) {
      const url = countryId === CountryId.Peru || countryId === CountryId.Colombia || countryId === CountryId.Chile ? 'nuevo-perfil' : `mfe-register/profile/${personId}`

      const urlProfile = createUrlAddressMaintenance({
        url,
        country: countryId,
      })

      window.location.assign(urlProfile)
    } else {
      window.location.assign(localStorage.getItem(URL_PROFILE_CN) || '/webfv')
    }
  }

  return (
    <StatusCardContainer>
      <ContentRejected>
        <WarningIcon>
          <Icon color="highlight" name="outlined-alert-warning" size="semi" />
        </WarningIcon>
        <Title>
          <Text align="center" variant="h5">
            {messages.title}
          </Text>
        </Title>
        <DescriptionContainer>
          <InfoContainer>
            <Text align="center" variant="subtitle1">
              {messages.description}
            </Text>
          </InfoContainer>
        </DescriptionContainer>
      </ContentRejected>
      <ActionContainer>
        <Button onClick={redirectToProfile}>{messages.myProfileButton}</Button>
      </ActionContainer>
    </StatusCardContainer>
  )
}
