import React from 'react'
import { Card, Typography } from '@naturacosmeticos/natds-web'
import { Divider } from '@naturacosmeticos/natds-web/dist/Components/Divider'
import { Icon } from '@/main/components'
import { getEloToggleByCountry } from '@/utils/getEloToggle'
import { CountryId } from '@/domain/models/country'
import { useCountry } from '@/utils/useCountry'
import { useCustomCardStyles } from './custom-card.styles'
import { StyledTypography } from './custom-card.styled'

type CustomCardProps = {
  title: string
  className?: string
  children: React.ReactNode,
  editButtonName?: string,
  editButtonLink?: string,
  disableClick?: boolean,
  onClickCapture?: () => void
}

export const CustomCard = ({
  title,
  className,
  children,
  editButtonName,
  editButtonLink,
  disableClick = false,
  onClickCapture = () => {}
}: CustomCardProps) => {
  const classComponent = useCustomCardStyles()

  const handleClick = () =>  {
    onClickCapture()
    if (!disableClick) {
      window.location.assign(editButtonLink)
    }
  }

  const countryId = useCountry()
  const isCountryPeruChileOrColombia = [CountryId.Peru, CountryId.Chile, CountryId.Colombia].includes(countryId)

  return (
    <Card className={`${classComponent.container} ${className}`}>
      <div className={classComponent.titleContainer}>
        {getEloToggleByCountry() || isCountryPeruChileOrColombia ? (
          <StyledTypography variant="subtitle1">
            {title}
          </StyledTypography>
        ) :
        (
          <Typography variant="subtitle1" className={classComponent.title}>
            {title}
          </Typography>
        )}
        {
          (editButtonName && editButtonLink)
          && (
          <div
            color="textPrimary"
            className={classComponent.editButton}
            onClickCapture={handleClick}
          >
            <Typography variant="body1">
              {editButtonName}
            </Typography>
            <Icon
              name="outlined-action-edit"
              size="tiny"
              className={classComponent.editIcon}
            />
          </div>
          )
        }
      </div>

      <Divider />

      {children}
    </Card>
  )
}
