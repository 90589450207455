import React, { useContext, useState, useEffect } from 'react'
import { MyLeaderDataResponse, PersonDataResponse } from '@/domain/use-cases/person'
import { GridContainer, GridItem, IconButton, Typography } from '@naturacosmeticos/natds-web'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import { makeRegisterMaitenancePageApi } from '@/main/pages/register-maintenance/api/make-register-maintenance-page-api'
import { Page } from '@/domain/models/page/page'
import {
  ProfileConfigs,
} from '@/domain/models/tenant/tenant-configs'
import { IdentityInfoContext } from '@/main/contexts'
import { Icon, Tooltip } from '@/main/components'
import { getFormattedPhoneNumber } from '@/main/pages'
import {
  MyProfileMessages,
} from '@/domain/models/messages/messages'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { countryCompanyToTenantIdMapper } from '@/domain/models/tenant/tenant-id'
import { CountryId } from '@/domain/models/country'
import { CustomCard } from '../components/custom-card'
import { useContactStyles } from './my-leader.styles'
import { useMyProfileStyles } from '../my-profile.styles'

export type ChangeGroupSectorLeader = {
  group: number
  sector: string
}

export type MyLeaderProps = {
  personData: PersonDataResponse
  onChangeGroupSectorLeader?: ({group, sector}: ChangeGroupSectorLeader) => void
}

const LEADER_RELATIONS = 'socialMedias,telephones,emails'
const DEFAULT_USER_NAME = 'CSC'
const DEFAULT_USER_ID = 'self-registration'
const DEFAULT_BUSINESS_MODEL_ID = 1

export const MyLeader: React.FC<MyLeaderProps> = ({ personData: { person }, onChangeGroupSectorLeader }) => {
  const [emails, setEmails] = useState([])
  const [phones, setPhones] = useState([])
  const [showPageMyLeader, setShowPageMyLeader] = useState(false)
  const [leaderName, setLeaderName] = useState('')
  const api = makeRegisterMaitenancePageApi()
  const { personId } = person

  const componentClasses = useContactStyles()
  const {
    companyId,
    countryId,
    businessModel,
    userName,
    userId,
  } = useContext(IdentityInfoContext)

  const tenantId = countryCompanyToTenantIdMapper[companyId][countryId]

  const {
    contact: {
      phoneMask: {
        maskRegex,
        mask,
      },
    },
  } = useTenantConfigs(tenantId, Page.MyProfile) as ProfileConfigs

  const { leader, } = usePageMessages(Page.MyProfile).messages as MyProfileMessages

  useEffect(() => {
    getLeaderData()
  }, [person])

  const getLeaderData = async () => {
    const { personDetails, commercialStructureDetails } = await api.getMyLeaderInfo({
      personId,
      countryId,
      companyId,
      businessModelId: businessModel || DEFAULT_BUSINESS_MODEL_ID,
      userId: userId || DEFAULT_USER_ID,
      userName: userName || DEFAULT_USER_NAME,
      relations: LEADER_RELATIONS
    }) as MyLeaderDataResponse

    if (personDetails) {
      const phoneMyLeader = countryId === CountryId.Brazil || countryId === CountryId.Mexico ? personDetails.phones.slice(0, 2) : [personDetails.phones.shift()]
      setShowPageMyLeader(true)
      setEmails(personDetails.emails)
      setLeaderName(personDetails.name)
      setPhones(phoneMyLeader)
    }

    if (commercialStructureDetails && onChangeGroupSectorLeader) {
      onChangeGroupSectorLeader({
        group: commercialStructureDetails.structureInfo.group.structureCode,
        sector: commercialStructureDetails.structureInfo.sector.structureName
      })
    }
  }
  const classComponent = useMyProfileStyles()
  const messages = usePageMessages(Page.MyProfile).messages as MyProfileMessages

  const isCountryPeruChileOrColombia = [CountryId.Peru, CountryId.Chile, CountryId.Colombia].includes(countryId)

  const iconSectionTitle = isCountryPeruChileOrColombia ? `${classComponent.iconSectionTitle} ${classComponent.title}` : classComponent.iconSectionTitle

  if (!showPageMyLeader) {
    return null
  }

  return (
    <GridItem className="natds2" lg={12} md={12} sm={12} xs={12}>
      <div className={classComponent.sectionTitle}>
        <Icon name="outlined-social-person" size="small" className={iconSectionTitle} />
        <Typography variant="h6" className={isCountryPeruChileOrColombia ? classComponent.title : ''}>{messages.leader.myLeader}</Typography>
      </div>
      <GridContainer className="natds1" spacing={2}>
        <GridItem className="natds2" lg={6} md={12} sm={12} xs={12}>
          <CustomCard title={leader.contact} className={componentClasses.container}>
            <div data-testid="my-leader">
              <div className={componentClasses.contactContainer}>
                <Typography variant="body2" color="textSecondary">
                  {leader.name}
                </Typography>
                <div className={componentClasses.contactNameContent} key={leaderName}>
                  <Typography variant="body1">
                    {leaderName && leaderName.toLowerCase().replace(/\b\w/g, function (char) {
                      return char.toUpperCase()
                    })}
                  </Typography>
                </div>
              </div>
              <div className={componentClasses.contactContainer}>
                <Typography variant="body2" color="textSecondary">
                  {leader.phone}
                </Typography>
                {
                  phones?.map((phone) => {
                    const phoneNumber = getFormattedPhoneNumber(phone)
                    const formattedPhone = phoneNumber?.replace(maskRegex, mask) || '-'
                    return (
                      <div data-testid="leaderPhone" className={componentClasses.contactPhoneContent} key={phone?.sequence}>
                        <Typography variant="body1">
                          {formattedPhone}
                        </Typography>
                        <TooltipButtonIcon
                          phone={phone}
                          formattedPhone={formattedPhone}
                          componentClasses={componentClasses}
                          title={leader.tooltipPhone}
                        />
                      </div>
                    )
                  })
                }
              </div>
              <div>
                <Typography variant="body2" color="textSecondary" className={componentClasses.contactSubtitleSpace}>
                  {leader.email}
                </Typography>
                {emails && emails.slice(0, 1).map(({ email, sequence }) => (
                  <Typography variant="body1" key={sequence}>
                    {email?.toLowerCase()}
                  </Typography>
                ))}
              </div>
            </div>
          </CustomCard>
        </GridItem>
      </GridContainer>
    </GridItem>
  )
}

const TooltipButtonIcon = ({
  phone, formattedPhone, componentClasses, title,
}) => (
  <Tooltip
    title={(
      <Typography variant="body2">
        {title}
      </Typography>
    )}
    enterTouchDelay={100}
    arrow
  >
    <IconButton
      data-testid={phone?.sequence}
      className={componentClasses.contactCopyButton}
      onClick={() => navigator.clipboard.writeText(formattedPhone)}
    >
      <Icon name="outlined-action-copylink" size="tiny" />
    </IconButton>
  </Tooltip>
)
