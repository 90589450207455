import { PhoneType } from '@/domain/models/person'
import { AddressPageForms } from '@/data/use-cases/register-maintenance/remote-get-multiple-address'
import { AddressPageType, AddressOrder } from '../../address'
import { AgreementProduct } from '../../agreement/agreement-product'
import { AttachmentType } from '../../attachments/attachments'
import { companyIconName } from '../../company/company-icon-name'
import { CompanyName } from '../../company/company-name'
import { DigitalStoreTermsEnum } from '../../digital-store/digital-store-terms-type'
import { ExtraEntry } from '../../digital-store/extra-entry'
import { Document } from '../../documents/documents'
import { Link } from '../../link'
import { VendorId } from '../../vendor/vendor-id'
import { shouldRenderRecommenderField } from './configs.AVON_MEX'
import {
  AddressConfigs,
  AttachmentsConfigs,
  AuthorizationCodeConfigs,
  CompletedConfigs,
  DigitalSignaturePageConfigs,
  DigitalStoreConfigs,
  DocumentationConfigs,
  MaintenancePersonalDataConfigs,
  MaintenanceRegisterConfigs,
  PagesTenantConfigs,
  PersonalDataConfigs,
  PreRegisterConfigs,
  ProfileConfigs,
} from './configs.d'

import {
  regexToValidateAllSpecialNumberCharactersForMexico,
  regexToValidateAllSpecialStreetCharactersForMexico,
  regexToValidateCommonSpecialCharactersForMexico,
} from './helpers'

const personalData: PersonalDataConfigs = {
  recommender: {
    shouldDisplay: ({ authenticated }) => shouldRenderRecommenderField(authenticated),
  },
  isDeliveryAddress: true,
  shouldUsePhoneSelect: false,
  addressWithExternalCode: true,
  showAsteriskInRequiredLabel: false,
  country: 'MEXICO',
  shouldUseSeparatedNames: true,
  namesOptions: ['firstName', 'middleName', 'lastName'],
  allowedCharsRegex: new RegExp(/^[a-zA-Z ]+$/),
  allocationStrategy: {
    searchAddress: {},
    searchAddressWithZipCode: {
      shouldGetGeolocationCode: true,
      shouldGetLocation: false,
      zipCode: {
        rules: {
          required: true,
          maxLength: 5,
          minLength: 5,
          pattern: new RegExp(/^\d{0,5}$/),
        },
        validChars: new RegExp(/^\d{0,5}$/),
        shouldDisplayLink: () => false,
      },
      city: {
        validChars: regexToValidateCommonSpecialCharactersForMexico,
        rules: {
          required: true,
          maxLength: 150,
        },
      },
      cologne: {
        validChars: regexToValidateCommonSpecialCharactersForMexico,
        rules: {
          required: true,
          maxLength: 150,
        },
      },
      complement: {
        validChars: new RegExp(/^[A-Za-z0-9. ]{0,7}$/),
        rules: {
          required: false,
          maxLength: 7,
        },
      },
      number: {
        validChars: regexToValidateAllSpecialNumberCharactersForMexico,
        rules: {
          required: true,
          maxLength: 8,
        },
        hasCheckboxWithoutAddressNumber: true,
      },
      reference: {
        validChars: regexToValidateCommonSpecialCharactersForMexico,
        rules: {
          required: true,
          maxLength: 40,
        },
      },
      state: {
        validChars: regexToValidateCommonSpecialCharactersForMexico,
        rules: {
          required: true,
          maxLength: 150,
        },
      },
      street: {
        validChars: regexToValidateAllSpecialStreetCharactersForMexico,
        rules: {
          required: true,
          maxLength: 24,
        },
      },
    },
    deliveryAddressWithZipCode: {
      deliveryZipCode: {
        rules: {
          required: true,
          maxLength: 5,
          minLength: 5,
          pattern: new RegExp(/^\d{0,5}$/),
        },
        validChars: new RegExp(/^\d{0,5}$/),
        shouldDisplayLink: () => false,
      },
      deliveryCity: {
        validChars: regexToValidateCommonSpecialCharactersForMexico,
        rules: {
          required: true,
          maxLength: 150,
        },
      },
      deliveryCologne: {
        validChars: regexToValidateCommonSpecialCharactersForMexico,
        rules: {
          required: true,
          maxLength: 150,
        },
      },
      deliveryComplement: {
        validChars: new RegExp(/^[A-Za-z0-9. ]{0,7}$/),
        rules: {
          required: false,
          maxLength: 7,
        },
      },
      deliveryNumber: {
        validChars: regexToValidateAllSpecialNumberCharactersForMexico,
        rules: {
          required: true,
          maxLength: 8,
        },
        hasCheckboxWithoutAddressNumber: true,
      },
      deliveryReference: {
        validChars: regexToValidateCommonSpecialCharactersForMexico,
        rules: {
          required: true,
          maxLength: 40,
        },
      },
      deliveryState: {
        validChars: regexToValidateCommonSpecialCharactersForMexico,
        rules: {
          required: true,
          maxLength: 150,
        },
      },
      deliveryStreet: {
        validChars: regexToValidateAllSpecialStreetCharactersForMexico,
        rules: {
          required: true,
          maxLength: 24,
        },
      },
    },
  },
}

const preRegister: PreRegisterConfigs = {
  documents: [
    {
      type: Document.Curp,
      name: 'curp',
      validChars: /^[\w˜Ñ]{0,18}$/m,
      isUpperCase: true,
      rules: {
        pattern: /^([A-Z][AEIOUX][A-Z]{2}[0-9]{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12][0-9]|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z0-9])([0-9])$/,
        minLength: 18,
        maxLength: 18,
      },
    },
  ],
  phones: [
    {
      type: PhoneType.CELLULAR,
      mask: [
        /[0-9]/,
        /[0-9]/,
        ' ',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        ' ',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
      ],
      length: 10,
    },
  ],
  shouldAcceptTerms: true,
  shouldUsePhoneSelect: false,
  link: {
    href: Link.consultCurp,
  },
}

const attachments: AttachmentsConfigs = {
  documents: [
    {
      type: Document.PassportMexico,
      name: 'Pasaporte',
      attachments: [{ title: '', type: AttachmentType.PassportMexico }],
    },
    {
      name: 'INE',
      attachments: [
        { title: 'Frente', type: AttachmentType.IneFront },
        { title: 'Dorso', type: AttachmentType.IneBack },
      ],
    },
  ],
  canChooseDocument: true,
}

const digitalStore: DigitalStoreConfigs = {
  showSkipButton: true,
  showEmailInfoRegistration: true,
  acceptTermsStrategy: DigitalStoreTermsEnum.DEFAULT,
  vendorId: VendorId.EMPTY,
  agreement: {
    products: [AgreementProduct.DIGITAL_STORE],
  },
  extraEntries: [
    {
      type: ExtraEntry.Clabe,
      name: 'clabe',
      mask: [
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
      ],
      isUpperCase: true,
      required: true,
      rules: {
        pattern: /^[0-9]{18}/,
        minLength: 18,
        maxLength: 18,
      },
    },
    {
      type: ExtraEntry.Rfc,
      name: 'rfc',
      mask: [
        /[a-zA-Z]/,
        /[a-zA-Z]/,
        /[a-zA-Z]/,
        /[a-zA-Z]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[a-zA-Z]/,
        /[0-9]/,
      ],
      isUpperCase: true,
      required: false,
      rules: {
        pattern: /^[A-Z]{4}[0-9]{7}[A-Z]{1}[0-9]{1}/,
        minLength: 13,
        maxLength: 13,
      },
    },
  ],
  showWarningAfterGetSubmitError: true,
  digitalStoreNameMaxOfChars: null,
  shouldUnlinkAgreements: true,
}

const address: AddressConfigs = {
  pageType: AddressPageType.SearchedAddress,
  shouldDisableTheFilledInput: true,
  zipCode: {
    rules: {
      maxLength: 5,
      pattern: new RegExp(/^\d{0,5}$/),
    },
    shouldDisplayLink: () => false,
  },
  complement: {
    rules: {
      maxLength: 50,
    },
    pattern: new RegExp(/^[A-Za-z0-9. ]*$/),
  },
  street: {
    maxLength: 50
  },
  validateSpecialCharacters: new RegExp(/^[a-zA-Z0-9\\-\\(\\)\\#\\_\\\\:\\,\\. ]*$/),
  fieldsOrder: [
    AddressOrder.STREET,
    AddressOrder.NUMBER,
    AddressOrder.COMPLEMENT,
    AddressOrder.REFERENCES,
    AddressOrder.COLOGNE,
    AddressOrder.NEIGHBORHOOD,
    AddressOrder.CITY,
    AddressOrder.STATE,
    AddressOrder.COUNTRY,
  ],
  showAlertOnApprovedPage: true,
}

const documentation: DocumentationConfigs = {
  shouldAcceptTermsWhenAuthenticated: true,
  agreementProducts: [AgreementProduct.RESELLER, AgreementProduct.BUREAU],
  shouldHaveBirthState: true,
  agreement: {
    openedCheckboxDialog: {
      first: false,
      second: true,
    },
    products: [
      {
        name: AgreementProduct.BUREAU,
        checkboxOrder: 'second',
      },
      {
        name: AgreementProduct.RESELLER,
        checkboxOrder: 'first',
      },
    ],
  },
}

const digitalSignaturePage: DigitalSignaturePageConfigs = {
  agreement: {
    previewContract: AgreementProduct.RESELLER,
    openedCheckboxDialog: {
      first: false,
      second: true,
    },
    products: [
      {
        name: AgreementProduct.BUREAU,
        checkboxOrder: 'second',
      },
      {
        name: AgreementProduct.RESELLER,
        checkboxOrder: 'first',
      },
    ],
  },
}

const completed: CompletedConfigs = {
  rejected: {
    hasEmailContact: false,
  },
  shouldSearchIndication: false,
  shouldCheckStatus: true,
  shouldOnlyShowConfirmation: false,
  shouldShowIndicativePerson: true,
  shouldShowContactInfos: false,
  shouldShowConfirmationDescription: true,
  goToClickButton: {
    show: true,
    path: `${process.env.LANDING_PAGE_URL}`,
  },
  companyIconName: companyIconName[CompanyName.Natura],
}

const authorizationCode: AuthorizationCodeConfigs = {
  phoneNumberHyphenIndex: 2,
}

const maintenanceRegisterPage: MaintenanceRegisterConfigs = {
  phoneNumberAreaCode: '52',
  phoneNumberOptions: {
    mask: [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
    maxLength: 10,
    minLength: 10,
  },
  phoneNumberSecondaryOptions: {
    mask: [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
    maxLength: 10,
    minLength: 10,
  },
  shouldEditEmail: true,
  shouldEditPhone: true,
  showPhoneNumberSecondary: true,
}

const maintenancePersonalDataPage: MaintenancePersonalDataConfigs = null

const profile: ProfileConfigs = {
  showMyLeader: true,
  showCnCredit: true,
  contact: {
    phoneMask: {
      maskRegex: /^(\d{5})(\d{5})$/,
      mask: '$1 $2',
  },
  editUrl: '/mfe-register/maintenanceRegisterPage/',
  minegocioEditUrl: '/mfe-register/maintenanceRegisterPage/',
  },
  consultantData: {
    editUrl: '/mfe-register/maintenancePersonalDataPage/',
    minegocioEditUrl: '/mfe-register/maintenancePersonalDataPage',
  },
  address: {
    getAddress: ({
      street,
      number,
      complement,
      cologne,
      neighborhood,
      district,
      city,
      state,
    }: AddressPageForms) => {
        const address = [state, city, district, cologne, neighborhood, street, number, complement].filter(Boolean).join(', ')
        return address
    },
    editUrl: '/mfe-register/maintenanceAddressPage/',
    minegocioEditUrl: '/mfe-register/maintenanceAddressPage',
  },
  hasDigitalSpace: false,
  digitalSpace: {
    digitalSpaceBaseUrl: 'https://www.natura.com.br/consultoria/',
    mySpaceDashboard:
      'https://cadastro-experiencia-digital-store-fe.qa.naturacloud.com/?country=mx&company=natura',
    openDigitalSpaceUrl:
      'https://cadastro-experiencia-digital-store-fe.qa.naturacloud.com/?country=mx&company=natura',
    showDigitalSpace: false, 
  },
  hasEditProfile: false,
  hasSelfDeclaration: false,
  hasGender: false,
  hasSocialName: false,
  showPhoneNumberSecondary: true,
  showGroupSectorLeader: false,
  showGrowthPlan: true,
}

export const naturaMexConfigs: PagesTenantConfigs = {
  documentation,
  personalData,
  attachments,
  preRegister,
  digitalStore,
  address,
  digitalSignaturePage,
  completed,
  authorizationCode,
  maintenanceRegisterPage,
  maintenancePersonalDataPage,
  profile,
}
